// @import "@repay/react-credit-card/dist/react-credit-card.css";

// config ideally
.amountInput {
  margin-bottom: 8px;
  font-size: 20px;
}

.amountInput input {
  font-size: 20px;
  font-weight: 700;
}

.card--transaction {
  margin-left: 10px;
  margin-right: 10px;
  padding: 0 !important;
}

.card--title {
  background-color: #262443;
  padding: 10px;
  border-radius: 5px;
  font-size: 20px;
  color: white;
}

.label--price {
  margin-top: 12px;
  margin-bottom: 12px;
  font-size: 22px;
  display: flex;
  justify-content: space-between;
}

.sub-label--price {
  margin-top: 12px;
  margin-bottom: 12px;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
}

.price--total {
  font-size: 15px;
  font-weight: bold;
}

// .MuiDrawer-root,
// .MuiDrawer-root .MuiDrawer-paper{
//   background-color: #52516a !important;
// }

.MuiDrawer-root a {
  color: white;
}

.stripeWrapper {
  // background-color: #52516A;
}

.StripeElement {
  margin: 15px auto;
  padding: 20px 0x;
  // color: #32325d;
  border: 1px solid transparent;
  border-radius: 4px;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.btn-pay {
  display: block;
  width: 100%;
  border: none;
  background: #7ec6c5;
  color: #000;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
}

.btn--primary {
  background: rgba(108, 224, 224, 1);
  color: white;
}

.makeStyles-root-1 {
  background-color: white !important;
}

.makeStyles-root-22 {
  background-color: white !important;
}


.MuiFormControl-root {
  margin-bottom: 20px;
}






.ReactCreditCard__container {
  position: relative;
  perspective: 1000px;
  width: 350px;
  height: 200px;
  font-size: 16px;
  text-align: left;
}

.ReactCreditCard {
  border-radius: 10px;
  line-height: 1;
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 315px;
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: all 400ms linear;
  transition: all 400ms linear;
}

.ReactCreditCard--shadow {
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

.ReactCreditCard--flipped {
  -webkit-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.ReactCreditCard--focused {
  opacity: 1;
  font-weight: 700;
}

.ReactCreditCard__front,
.ReactCreditCard__back {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  -webkit-transition: all 400ms linear;
  transition: all 400ms linear;
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  border-radius: 10px;
  background: #777777;
  z-index: 0;
}

.ReactCreditCard__back {
  -webkit-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

/* Signature Strip*/
.ReactCreditCard__back::after {
  content: "";
  display: block;
  background-color: #fff;
  background-image: -webkit-linear-gradient(#fff, #fff);
  background-image: -webkit-gradient(linear,
      left top,
      left bottom,
      from(#fff),
      to(#fff));
  background-image: linear-gradient(#fff, #fff);
  width: 80%;
  height: 16%;
  position: absolute;
  top: 40%;
  left: 2%;
}

.ReactCreditCard__back .ReactCreditCard__shiny {
  position: absolute;
  top: 66%;
  left: 2%;
}

.ReactCreditCard__shiny {
  width: 50px;
  height: 35px;
  margin-bottom: 10px;
  border-radius: 5px;
  background: #ccc;
  position: relative;
}

.ReactCreditCard__shiny:after {
  content: attr(data-after);
  position: absolute;
  left: 120%;
  top: 5%;
  color: white;
  font-size: 7px;
  width: 230px;
  opacity: 0.5;
}

.ReactCreditCard__shiny:before {
  content: " ";
  display: block;
  width: 70%;
  height: 60%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #d9d9d9;
  position: absolute;
  top: 20%;
}

.ReactCreditCard__lower {
  width: 80%;
  position: absolute;
  left: 10%;
  bottom: 30px;
}

.ReactCreditCard__logo {
  position: absolute;
  right: 0;
  top: 0;
}

.ReactCreditCard__bar {
  background-color: #444;
  background-image: -webkit-linear-gradient(#444, #333);
  background-image: -webkit-gradient(linear,
      left top,
      left bottom,
      from(#444),
      to(#333));
  background-image: linear-gradient(#444, #333);
  width: 100%;
  height: 20%;
  position: absolute;
  top: 10%;
}

.ReactCreditCard__number {
  font-family: "Bitstream Vera Sans Mono", Consolas, Courier, monospace;
  font-size: 24px;
  clear: both;
  margin-bottom: 30px;
}

.ReactCreditCard__name {
  text-transform: uppercase;
  font-family: "Bitstream Vera Sans Mono", Consolas, Courier, monospace;
  font-size: 20px;
  max-height: 45px;
  position: absolute;
  bottom: 0;
  width: 190px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: horizontal;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2em;
}

.ReactCreditCard__expiration {
  font-family: "Bitstream Vera Sans Mono", Consolas, Courier, monospace;
  letter-spacing: 0em;
  position: relative;
  float: right;
  width: 25%;
}

.ReactCreditCard__expiration:before {
  content: attr(data-before);
  margin-bottom: 2px;
  font-size: 7px;
  text-transform: uppercase;
}

.ReactCreditCard__expiration:after {
  position: absolute;
  content: attr(data-after);
  text-align: right;
  right: 100%;
  margin-right: 5px;
  margin-top: 2px;
  bottom: 0;
}

.ReactCreditCard__expiration:before,
.ReactCreditCard__expiration:after {
  font-family: "Helvetica Neue";
  font-weight: bold;
  font-size: 7px;
  white-space: pre;
  display: block;
  opacity: 0.5;
}

.ReactCreditCard__cvc {
  position: absolute;
  -webkit-transition-delay: 600ms;
  transition-delay: 600ms;
  font-family: "Bitstream Vera Sans Mono", Consolas, Courier, monospace;
  font-size: 14px;
}

.ReactCreditCard__back .ReactCreditCard__cvc {
  top: 40%;
  left: 85%;
}

.ReactCreditCard__front .ReactCreditCard__cvc {
  top: 19%;
  right: 18%;
}

.ReactCreditCard__display {
  color: white;
  font-weight: normal;
  opacity: 0.5;
  -webkit-transition: opacity 400ms linear;
  transition: opacity 400ms linear;
}

.ReactCreditCard--focused {
  opacity: 1;
  font-weight: 700;
}

.ReactCreditCard .ReactCreditCard__front:before,
.ReactCreditCard .ReactCreditCard__back:before {
  content: " ";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  border-radius: 10px;
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  transition: all 400ms ease;
}

.ReactCreditCard:not(.ReactCreditCard--radial) .ReactCreditCard__front:before,
.ReactCreditCard:not(.ReactCreditCard--radial) .ReactCreditCard__back:before {
  background-image: repeating-linear-gradient(45deg,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px),
    repeating-linear-gradient(135deg,
      rgba(255, 255, 255, 0.05) 1px,
      rgba(255, 255, 255, 0) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.03) 4px),
    repeating-linear-gradient(90deg,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px),
    repeating-linear-gradient(210deg,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px),
    -webkit-linear-gradient(-245deg, rgba(255, 255, 255, 0) 50%, rgba(255,
        255,
        255,
        0.4) 70%, rgba(255, 255, 255, 0) 90%);
  background-image: repeating-linear-gradient(45deg,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px),
    repeating-linear-gradient(135deg,
      rgba(255, 255, 255, 0.05) 1px,
      rgba(255, 255, 255, 0) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.03) 4px),
    repeating-linear-gradient(90deg,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px),
    repeating-linear-gradient(210deg,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px),
    linear-gradient(-25deg,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 0.4) 70%,
      rgba(255, 255, 255, 0) 90%);
}

.ReactCreditCard.ReactCreditCard--radial .ReactCreditCard__front:before,
.ReactCreditCard.ReactCreditCard--radial .ReactCreditCard__back:before {
  background-image: repeating-linear-gradient(45deg,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px),
    repeating-linear-gradient(135deg,
      rgba(255, 255, 255, 0.05) 1px,
      rgba(255, 255, 255, 0) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.03) 4px),
    repeating-linear-gradient(90deg,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px),
    repeating-linear-gradient(210deg,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px),
    repeating-radial-gradient(circle at 30% 30%,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px),
    repeating-radial-gradient(circle at 70% 70%,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px),
    repeating-radial-gradient(circle at 90% 20%,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px),
    repeating-radial-gradient(circle at 15% 80%,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px),
    -webkit-linear-gradient(-245deg, rgba(255, 255, 255, 0) 50%, rgba(255,
        255,
        255,
        0.4) 70%, rgba(255, 255, 255, 0) 90%);
  background-image: repeating-linear-gradient(45deg,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px),
    repeating-linear-gradient(135deg,
      rgba(255, 255, 255, 0.05) 1px,
      rgba(255, 255, 255, 0) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.03) 4px),
    repeating-linear-gradient(90deg,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px),
    repeating-linear-gradient(210deg,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px),
    repeating-radial-gradient(circle at 30% 30%,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px),
    repeating-radial-gradient(circle at 70% 70%,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px),
    repeating-radial-gradient(circle at 90% 20%,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px),
    repeating-radial-gradient(circle at 15% 80%,
      rgba(255, 255, 255, 0) 1px,
      rgba(255, 255, 255, 0.03) 2px,
      rgba(255, 255, 255, 0.04) 3px,
      rgba(255, 255, 255, 0.05) 4px),
    linear-gradient(-25deg,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 0.4) 70%,
      rgba(255, 255, 255, 0) 90%);
}

.ReactCreditCard--identified .ReactCreditCard__front:before,
.ReactCreditCard--identified .ReactCreditCard__back:before {
  opacity: 0.7;
}

.ReactCreditCard--ie-11.ReactCreditCard--flipped {
  transform: 0deg;
}

.ReactCreditCard--ie-11.ReactCreditCard--flipped .ReactCreditCard__front,
.ReactCreditCard--ie-11.ReactCreditCard--flipped .ReactCreditCard__back {
  transform: rotateY(0deg);
}

.ReactCreditCard--ie-11 .ReactCreditCard__back::after {
  left: 14%;
}

.ReactCreditCard--ie-11.ReactCreditCard--flipped .ReactCreditCard__back .ReactCreditCard__cvc {
  transform: rotateY(180deg);
  left: 5%;
}

.ReactCreditCard--ie-11.ReactCreditCard--flipped .ReactCreditCard__back .ReactCreditCard__shiny {
  left: 84%;
}

.ReactCreditCard--ie-11.ReactCreditCard--flipped .ReactCreditCard__back .ReactCreditCard__shiny::after {
  left: -480%;
  transform: rotateY(180deg);
}

.ReactCreditCard--ie-11.ReactCreditCard--amex .ReactCreditCard__back {
  display: none;
}

/* Card Brand Styles */

.ReactCreditCard--unknown {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ReactCreditCard--unknown .ReactCreditCard__logo {
  visibility: hidden;
}

.ReactCreditCard--dankort .ReactCreditCard__front,
.ReactCreditCard--dankort .ReactCreditCard__back {
  background: #0055c7;
}

.ReactCreditCard--discover .ReactCreditCard__front,
.ReactCreditCard--discover .ReactCreditCard__back {
  background: #86b8cf;
}

.ReactCreditCard--mastercard .ReactCreditCard__front,
.ReactCreditCard--mastercard .ReactCreditCard__back {
  background: #0061a8;
}

.ReactCreditCard--visa .ReactCreditCard__front,
.ReactCreditCard--visa .ReactCreditCard__back {
  background: #191278;
}

.ReactCreditCard--amex .ReactCreditCard__front,
.ReactCreditCard--amex .ReactCreditCard__back {
  background-color: #108168;
}


.checkbook-account:hover {
  cursor: pointer;
  border: 2px solid #c3c3c3;
}
